@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* border: 1px solid blue; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100vh; */
  /* background-color: #fcfcfc; */

  /* border: 1px solid blue; */
}

/* :root {
  --main-color: #dfff02;
  --continue-button-font: #212121;
  --back-button-color: #212121;
  --secondary-color: #d8ef46;
}

[data-parceiro="a018800000XXAC6AAP"] {
  --main-color: #660099;
  --secondary-color: #660099;
  --continue-button-font: #ffffff;
  --back-button-color: #660099;
} */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-noto {
  font-family: "Kumbh Sans" !important;
  background-color: #fff;
  color: black;
}

.fontLabel label {
  font-size: 16px;
}

.bg-white {
  background-color: #fff;

  span {
    color: #000;
    font-family: "Noto Sans", sans-serif !important;
  }
}

.subtitle-step-representante {
  font-family: "Kumbh Sans", sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  color: #000;
}

.error-input {
  all: unset;
  /* position: absolute;
  top: 101%;
  left: 7px; */
  color: red;
  font-size: 14px;
  display: none;
  width: 270px;
  /* opacity: 0; */
}
